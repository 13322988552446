export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '1.1.7',
    name: 'prepplus-portal',
    versionDate: '2025-01-22T22:50:22.622Z',
};
export default versions;
